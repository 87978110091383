.BuscadorInput{
    display: block;
    position: absolute;
    transition: .3s all;
    opacity: 1;
    margin: auto;
    top: 0px;
    transform: translateX(0%);
    width: 100%;
    height: 100vh;
    z-index: 9999999;
   


    &.Hide{
        transform: translateX(100%);
        opacity: 0;
    }

    .Buscador{

        position: absolute;
        background-color: #89bd31;
        width: 100%;
        padding:20px;
         display: flex;
         align-items: center;
         justify-content: space-between;
         z-index: 1;

         .InputContainer{
            border-radius: 20px;
            background-color: white;
            display: flex;
            height: 100px;
            height: 40px;
            align-items: center;

             
              span{
                 margin: 10px;
                 font-size: 32px;
                 cursor: pointer;
                 color: gray
             }
             input{
                 width: 100%;
                 height: 40px;
                 border: none;
                 border-radius: 20px;
                 font-size: 18px;
                 padding: 10px;
                 outline: none;
             }
         }

    }
}

.BuscadorInputIntegrado{
    display: none;
}

@media(min-width:992px) {
    .BuscadorInput{
        display: none;
    }

    .BuscadorInputIntegrado{
        display: block;
        .Buscador{

            position: absolute;
            /* background-color: #89bd31; */
            width: 200px;
            /* padding: 20px; */
            display: flex;
            align-items: center;
            justify-content: space-between;
            z-index: 1;
            top: 0;
            bottom: 0;
            right: 10px;

            .InputContainer{
                border-radius: 20px;
                background-color: white;
                display: flex;
                height: 100px;
                height: 40px;
                align-items: center;

                span{
                    margin: 10px;
                    font-size: 32px;
                    cursor: pointer;
                    color: gray;
                }
                input{
                    width: 100%;
                    height: 40px;
                    border: none;
                    border-radius: 20px;
                    font-size: 16px;
                    padding: 10px;
                    outline: none;
                }
            }
        

        }

        .ResultListContainer{
            position: absolute;
            width: 100%;
            left: 0;
            padding: 0% 20%;
            height: 600px;
            top: 100px;
            /* border-radius: 20px; */
            overflow: hidden;
            opacity: 1;
            transform: translateX(0%);
            transition: .3s all;

             &.Disable{
                transform: translateX(100%);
                opacity: 0;
            }
        }
    }

}