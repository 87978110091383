.Carousel {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 80px;

    .LogoImage{
        position: absolute;
        width: 150px;
        right: 15px;
        bottom: 52px;
        display: none;
    }


    .ImageContainer {
        display: flex;
        flex-direction: row;
        // transition: all .5s;
        width: 100%;
        height: 50vh;

        &:active {
            cursor: grab;
        }

        .Image {
            width: 100%;
            min-width: 100%;


            height: 50vh;
            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 1em;
            font-weight: bolder;
            background-color: #000;

            position: relative;

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }

            &.displayed {
                // flex-grow: 3;
            }

            // &:nth-child(1){
            //     background-color: #a02f2f;
            //     color: white;
            // }
            // &:nth-child(2){
            //     background-color: #1c1c3c;
            //     color: white;
            // }
            // &:nth-child(3){
            //     background-color: #265a26;
            //     color: white;
            // }

            display: flex;

            .TextLabel{
                position: absolute;
                color: white;
                font-size: 20px;
                text-transform: uppercase;
                margin: auto 60px;
                background-color: #93c04991;
                padding: 10px;
                left: 0;
                right: 0;
            }


        }
    }

    .CarouselPosition {
        position: absolute;
        display: flex;
        flex-direction: column;
        // bottom: 5px;
        left: 15px;
        bottom: 0px;
        margin-bottom: 100px;
        cursor: pointer;


        span {
            // cursor: pointer;
            // width: 10px;
            // height: 10px;
            // border: 1px solid white;
            margin: 5px;
            color: white;
            transition: .5s all;
            font-size: 14px;


            &.selected {
                // background-color: white;
                font-weight: bolder;
                font-size: 20px;
            }
        }
    }

    .CarouselBottomImage {
        position: absolute;
        width: 100%;
        height: 100px;
        background: transparent;
        bottom: -3px;
    }

    &Information {
        position: absolute;
        display: flex;
        flex-direction: column;
        color: #fff;
        // left: 0px;
        // right: 0px;
        // top: 0px;
        // bottom: 0;
        // margin: auto;
        // height: auto;
    }

    


}

.Button {
    border: none;
    padding: 10px;
    border-radius: 2em;
    min-width: 120px;
    text-transform: uppercase;
    // background: rgb(94,3,150);
    // background: linear-gradient(140deg, rgba(94,3,150,1) 0%, rgba(0,22,71,1) 100%);
    // background: rgb(73,2,116);
    // background: linear-gradient(140deg, rgba(73,2,116,1) 0%, rgba(255,158,0,1) 100%);
    background: rgb(255, 7, 141);
    background: linear-gradient(140deg, rgba(255, 7, 141, 1) 0%, rgba(255, 196, 0, 1) 100%);
    color: white;
}

.Clock {
    position: absolute;
    top: 135px;
    right: 0px;
    z-index: 1;
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    .Carousel {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;



        .ImageContainer {
            display: flex;
            flex-direction: row;
            // transition: all .5s;
            width: 100%;
            height: 90vw;

            &:active {
                cursor: grab;
            }

            .Image {
                width: 100%;
                min-width: 100%;
                position: relative;


                height: 90vw;
                display: flex;
                align-items: center;
                justify-content: center;

                font-size: 3em;
                font-weight: bolder;
                background-color: #000;

                img {
                    object-fit: cover;
                    width: 100%;
                }

                &.displayed {
                    // flex-grow: 3;
                }

                .TextLabel{
                    bottom: 0;
                    left: 0;
                    margin-bottom: 100px;
                    right: unset;
                    max-width: 50%;
                    text-align: left;
                }
            }
        }

        .CarouselPosition {
            bottom: 5px;
            top: unset;

        }

        &Information {
            position: absolute;
            color: #fff;
        }


    }

    .Button {
        border: none;
        padding: 10px;
        border-radius: 2em;
        min-width: 120px;
        text-transform: uppercase;
        // background: rgb(94,3,150);
        // background: linear-gradient(140deg, rgba(94,3,150,1) 0%, rgba(0,22,71,1) 100%);
        // background: rgb(73,2,116);
        // background: linear-gradient(140deg, rgba(73,2,116,1) 0%, rgba(255,158,0,1) 100%);
        background: rgb(255, 7, 141);
        background: linear-gradient(140deg, rgba(255, 7, 141, 1) 0%, rgba(255, 196, 0, 1) 100%);
        color: white;
    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {

    .Carousel {

        .LogoImage{
            
                position: absolute;
                width: 245px;
                right: 15px;
                bottom: 111px;
                display: block;
            
        }


        .ImageContainer {
            height: 90vw;

            .Image {
                height: 90vw;
            }
        }



    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .Carousel {


        .ImageContainer {
            height: 90vh;

            .Image {
                height: 90vh;

            }
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {}
