.LinkInteresContainer{

    padding: 0 10px;
}

.ListaLinkDeInteres {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    // justify-content: center;


    transition: .5s all;
    overflow: hidden;

}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    .ListaLinkDeInteres {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .ListaLinkDeInteres {
        // align-items: flex-end;   
        // justify-content: flex-end;    
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {}