.LinkPrincipal {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-top: 15px;
    box-shadow: 0 3px 6px #00000080;
    border-radius: 1em;
    overflow: hidden;
    min-height: 65px;
    color: #000000;
    cursor: pointer;
    background-color: white;

    text-decoration: none;

    position: relative;

    margin: 15px 5px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        background-color: #001871;
        height: 10px;
        width: 80%;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        background-color: #C8102E;
        height: 10px;
        width: 45%;
    }

    .Icon {
        font-size: 30px;
        margin: 10px;
        margin-right: 15px;
    }

    .Label {
        flex-grow: 2;
        text-align: center;

        display: flex;
        flex-direction: column;
    }

}

@media(min-width:576px) {
    .LinkPrincipal {
        margin: 10px;

        .Label {
            text-align: center;
        }
    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
    .LinkPrincipal {
        margin: 10px;
        flex-direction: row;

        .Label {
            text-align: left;
        }
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .LinkPrincipal {
        max-width: 250px;
        max-height: 64px;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {}