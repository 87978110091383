.PlaceLink {
    color: white;
    padding: 10px;
    border-radius: 10px;
    margin: 10px 3px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: .5s;
    text-decoration: none;

    .Shadow {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: gray;
        position: absolute;
    }


    &:hover {
        transform: translateY(-4px);
        box-shadow: 0 3px 6px #0000003b;

    }


}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    .PlaceLink {
        margin: 0px 0px 10px 3px;
        border-radius: 0px 0px 10px 10px;
    }

}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {}