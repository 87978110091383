.RichTextReader{
    & > p{
        text-align: justify;
        font-size: 1.66em;

        > * {
            font-size: 1em;
        }
    }

    strong {
        font-size: 1em;
    }
}

.Image{
    width:100%
}

.Table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 40px;
}

.List{
    list-style: none;
}

.Link{
    font-size: 1em;
}

.ParradoPersonalizado{
    padding: 10px 0px;
}

.ListItem{

    &::before {content: "•"; 
        color: #94c248;
        display: inline-block;
        width: 10em;
        font-size: 28px;
        margin-right: 15px;
        width: 15px;
    }

    & >div {
        display: inline;
        p{
            display: inline;
        }
    }
}

.TableHeader{
    tr {
        td {
            border-bottom: 2px solid #94c248;
            padding: 10px 2px
        }
        p{
            font-weight: bold;
        }
    }
}

.TableBody{
    tr {
        td {
            border-bottom: 1px solid #94c248;
            padding: 10px 2px;
            
            a{
                display: inline-block;
            }
        }
    }
}