.ResultElement{
    display: flex;
    overflow: hidden;
    flex-direction: column;
    background-color: white;
    color: black;
    border-radius: 20px;
    padding: 5px;
    margin: 5px 0px;

    .ImagenPrincipal{
        width: 100%;
        height: 100%;
        border-radius: 20px;
        overflow: hidden;
    }

    .BajadaTexto{
        padding: 10px;
        text-align: left;
        p{
            margin: 0;
            padding: 0;
        }

        .Titular{
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 2px;
        }
        
        .Bajada{
            margin-top: 2px;
            font-size: 14px;
        }
    }
}


@media(min-width:992px) {
    .ResultElement{
        flex-direction: row;
        height: 200px;

        .BajadaTexto{
            width: 200%;
            flex-grow: 2;
        }
    }
}