.Revista{
    margin: 50px;
    margin-top: 150px;

    .TituloPrincipal{
        padding-left: 15px;
    }
    .Articulos{
        margin: 20px 0px;
    }
    margin-bottom: 100px;
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    .Revista{
        .Articulos{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            & > div {
                width: 50%;
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
    .Revista{
        .Articulos{
            & > div {
                width: 33%;
            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .Revista{
        .Articulos{
            & > div {
                width: 25%;
            }
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {}