.Logo{
    width: 80px;
    height: 60px;
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
    .Logo{
        width: 80px;
        height: 60px;

    }
    
}