.Contacto {

    margin: 10px 0px;
    color: white;

    .Title {
        text-align: center;
        // text-decoration: underline;
        font-size: 18px;
        margin: 0;
        padding: 0;

        font-weight: 200;
        padding-bottom: 10px;
    }

    .Value {
        text-align: center;
        font-size: 18px;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        font-weight: bold;
    }
}