@import './../../assets/styles/utils/fonts.scss';
@import './../../assets/styles/utils/color.scss';






.NavBar {
    width: 100%;
    position: fixed;
    // position: relative;
    top: 0px;
    z-index: 99998;
    color: $color-clear-contrast;
    background-color: #89BD31E6;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s all;


    max-width: 100vw;

    &.ActiveBanner{
        transform: translateY(68px);
        // transform: translateY(43px);
    }

    .Header{

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0px 60px;

        .Municipalidad {
            margin:0;
            padding: 0;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: white;
            text-shadow: 0px 3px 6px #00000099;
            // flex-grow: 3;
        }

    }


    .Icon {
        
        position: absolute;
        /* top: 0px; */
        bottom: 0px;
        left: 0px;
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        z-index: 10000;


        span {
            margin: 10px;
        }

        .MenuIcon {
            font-size: 40px;
        }

        .MenuWord{
            display: none;
        }


    }

    .Buscador{
        
        cursor: pointer;
        position: absolute;
        right: 10px;
        bottom: 0;
        top: 0;
        display: flex;
        align-items: center;

        span{
            font-size: 40px;
        }

    }

    
    
}
.Overlay{
    cursor: pointer;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #0000005e;
    transform: translateX(0%);
    transition: 1s opacity ;

    &.Hide {
        opacity: 0;
        transform: translateX(-120%);
    }
}

@media(min-width:576px) {
    .NavBar {

         .Icon {
                

            .MenuWord{
                display: block;
                font-size: 20px;
                font-weight: bold;
            }


        }
        

        .Header{


            .Municipalidad {

                
    
            }

        }
    }

}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
    .NavBar {

         &.ActiveBanner{
        // transform: translateY(68px);
        transform: translateY(43px);
    }

        

        .Header{


            .Municipalidad {

                font-size: 36px;
                font-weight: bold;
    
            }

        }


        .MenuDesplegable {

            height: 80vh;

            .MenuContainer {

                .MenuOptions {
                    flex-direction: row;
                    height: 100%;

                }

            }
        }
        .Buscador{
            display: none;
        }
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .NavBar {

        .MenuDesplegable {

            height: 80vh;

            .MenuContainer {

                .MenuOptions {
                    flex-direction: column;

                }
            }
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {}