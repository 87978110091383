.NoticiasMasVistas {
    overflow: hidden;
    margin-left: 0px !important;
    padding-left: 10px;
    order: 3;
    // margin-right: 15px;
    


    .TituloPrincipal{
        margin: 10px;
    }

    .Cabecera{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ArrowContainer{
            display: flex;
            align-items: center;
            justify-content: center;

            .Arrow{
                font-size: 24px;
                transition: .5s all;
                cursor: pointer;
    
                &:hover {
                    font-size: 30px;
                }
            }
        }
    }
    .ListaNoticias {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        min-height: 200px;
        position: relative;
        margin-bottom: 50px;
        transition: .5s all;
    }
}

.Wrap{
    flex-wrap: wrap;
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    

}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
    
}


// Large devices (desktops, 992px and up)
@media(min-width:992px) {

}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {}