
@mixin child-width($child) {
    .Swipper-#{$child} {
        width: (100%/$child);
        // height: (100%/$child);
    }
}

@for $i from 2 through 12 {
    @include child-width($i);
}

.Swipper-1  {
    width: 100%;
}


// Small devices (landscape phones, 576px and up)
@media(min-width:576px){
    .ListaLinkDeInteres{
        flex-direction: row;
    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px){
 
}

// Large devices (desktops, 992px and up)
@media(min-width:992px){
    .ListaLinkDeInteres{
        // align-items: flex-end;   
        // justify-content: flex-end;    
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px){

}