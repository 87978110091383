.Alcalde{
    margin: 150px 30px 200px 30px;

    .Justify{
        text-align: justify;
        margin: 48px 0px;
    }

    .Justify p{
        font-size: 1.63095em;
    }
    .DatosAlcalde{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-left: 50px;
        margin-right: 50px;

        .Parrafo{
            margin-top: 48px;
            text-align: justify;
        }
        .Parrafo p{
            font-size: 1.63095em;
        }


    }
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
    .Alcalde{
        margin: 150px 65px 200px 65px;
        .DatosAlcalde{

            margin-left: 80px;
            margin-right: 80px;



        }
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .Alcalde{
        margin: 150px 100px 200px 100px;
        .DatosAlcalde{
            margin-left: 250px;
            margin-right: 250px;
        }
    }

}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {

}