.ImageLoader {

    position: relative;
    // min-height: 200px;
    transition: .5s all;
    width: 100%;

    .Image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center center;
    }

    .Layout {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #ececec;
        top: 0;

    }

    &.Standar {
        position: relative;
        width: 100%;
        padding-top: 100%;
        overflow: hidden;

        .Image{
            position: absolute;
            top: 0;
            left: 0;
        }

    }

}