@import './../../assets/styles/utils/fonts.scss';
@import './../../assets/styles/utils/color.scss';

.Button {
    background-color: $color-primary;
    color: $color-clear-contrast;
    border: none;
    text-transform: uppercase;
    min-width: 250px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    // font-size: 14px;
    // font-weight: bold;    
    border-radius: 100px;
    cursor: pointer;
    
    .Content{
        // padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
    }
    

    .Ripple{
        border-radius: 100%;
        position: absolute;
        width: 0px;
        height: 0px;
        transform: translate(-50%, -50%);
        background-color: rgba(0,0,0,.15);
        opacity: 1;


        &.Click{
            animation: RippleTrigger .8s;
        }
    }

    @keyframes RippleTrigger {
        from{
            opacity: 1;
            padding: 0px;
        }
        to{
            opacity: 0;
            padding: 500px;
            
        }
    }

    



}