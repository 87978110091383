.ConsejoSociedadCivil{
    margin: 150px 30px 200px 30px;
    text-align: justify;

    .Justify{
        text-align: justify;
        margin: 48px 0px;
    }

    .Consejeros{
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;

        & > * {
            margin: 20px;
        }

        .Parrafo{
            margin-top: 48px;
            text-align: justify;
        }


    }
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    .ConsejoSociedadCivil{
        margin: 150px 80px 200px 80px;

    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {

}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
.ConsejoSociedadCivil{
    margin: 150px 100px 200px 100px;

    
}


}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {

}