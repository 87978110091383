.News {

    width: 100%;
    border-radius: 1rem;
    box-shadow: 0px 3px 6px #00000029;
    display: flex;
    overflow: hidden;
    position: relative;
    margin: 10px 0px;
    background-color: white;

    .Share {
        width: 45px;
        height: 45px;
        position: absolute;
        z-index: 1;
        background-color: white;
        padding: 5px;
        border-radius: 100px;
        color: #C8102E;
        display: flex;
        align-items: center;
        justify-content: center;




    }

    .Image {
        position: relative;
        height: 200px;

        .ImageSrc {
            width: 100%;
            height: 100%;
            background-color: yellowgreen;
        }

        .Bottom {
            position: absolute;
            bottom: -1px;
            left: -1px;
            right: -1px;
            // width: 100%;
            height: 45px;
            background-position: bottom;
                background-repeat: repeat-x;
            // background-size: cover;
        }


    }

    .Text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;
    }

    .Actions {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: 0px;

        small {
            margin: 10px;
        }

        button {
            border: none;
            border-radius: 100px;
            background-color: #89BD31;
            color: white;
        }

        div {
            border: none;
            border-radius: 100px;
            background-color: #AFAFAF;
            color: white;
            width: 60px;
        }
    }


    &.Default {
        // height: 365px;

        flex-direction: column;
        position: relative;

        .Share {
            right: 10px;
            top: 10px;
            z-index: 2;

            .ShareIcon {
                font-size: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

        }

        .ShareOptions {
            background-color: white;
            font-size: 20px;
            display: flex;
            height: 40px;
            padding: 2px;
            border-radius: 50px 0px 0px 50px;
            position: absolute;
            top: 12.5px;
            opacity: 0;
            padding-right: 19px;
            right: 39px;
            transform: translateX(200px);
            transition: .4s all;
            z-index: 1;

            align-items: center;
            justify-content: space-between;

            &.Open {
                opacity: 1;
                transform: translateX(0px);

            }


            .ShareItemOption {
                margin: 5px;
                display: flex;
                align-items: center;
            }
        }

        .Text {
            padding: 20px 10px 40px 10px;
        }

    }

    &.Horizontal {
        min-height: 275px;

        .Share {
            top: 10px;
            left: 10px;
        }

        .Image {
            min-width: 35%;


            .Bottom {
                bottom: 0;
                right: 0;
                width: 40px;
                height: 100%;
            }

        }

        .Text {
            min-width: 65%;
            padding: 10px 20px;
        }

        // .Actions{
        //     display: flex;
        //     align-items: center;
        //     small{
        //         margin: 10px;
        //     }

        //     button{
        //         border: none;
        //         border-radius: 100px;
        //         background-color: #89BD31;
        //         color: white;
        //     }
        // }

    }

    &.Vertical {
        height: 502px;
        flex-direction: column;

        .Share {
            left: 10px;
            top: 10px;
        }

        .Image {
            height: 50%;
        }

        .Text {
            min-height: 50%;
            padding: 20px 10px;
        }
    }

    &.Small {
        .Share {
            right: 10px;
            top: 10px;
        }

        .Image {
            display: none;
        }

        .Text {
            min-height: 50%;
            padding: 25px 25px 10px 25px;
        }
    }


}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    .News {
        width: 100%;

           .Image {
              .Bottom {
                background-position: bottom;
                height: 46px;
                background-repeat-y: no-repeat;
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {

    .News {
        &.Default {
            height: 460px;
        }
        &.Vertical {
            width: 49%;
        }

        &.Small {
            width: 49%;
        }

      
    }

}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .News {
        &.Default {
            height: 400px;
            width: 49%;
        }

        &.Small {
            width: 24.5%;
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {
    .News {
        &.Vertical {
            width: 24%;
        }

        .Image {
              .Bottom {
                background-position: bottom;
                height: 46px;
                background-repeat-y: no-repeat;
            }
        }
    }
}