@import './../../assets/styles/utils/color.scss';

.Titulo{
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    padding: 0;
    padding-bottom: 5px;

    span{
        font-size: 18px;
        font-weight: 200;
    }
}

.Footer {
    background-color: #89bf3f;
    color: $color-clear-contrast;
    position: relative;
    padding-top: 50px; // padding-top: 120px;
    margin-top: 60px;

    .FooterContent {
        display: flex;
        flex-direction: column-reverse;
        overflow: hidden;
        position: relative;
        flex-grow: 2;

        .BgImage {
            background-color: #707070;
            width: 100%;
            height: 100%;
            position: absolute;
            transform: rotate(45deg) translateY(588px);

        }

        .ContainerLogo {
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column-reverse;
            // height: 100%;
            position: relative;
            padding: 10px;


            &::before {
                content: '';
                clip-path: polygon(0 0, 0% 100%, 100% 100%);
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: gray;
            }


            .ImageContainer{
                z-index: 1;
                padding: 50px;
                
                img {
                    width: 100%;
                    object-fit: cover;
                    object-position: center center;
                    z-index: 1;
                }
            }
            
            


        }

        .Enlaces {
            flex-grow: 2;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .FooterLink {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

            }

            .ContactoTelefonico {

                .Contacto {

                    margin: 10px 0px;

                    .Title {
                        text-align: center;
                        text-decoration: underline;

                        p {
                            font-weight: 200 !important;
                            font-size: 3;
                        }
                    }

                    .Value {
                        text-align: center;

                        p {
                            font-weight: bold !important;
                            font-size: 3.2;
                        }
                    }
                }
            }


            .RedesSociales {
                margin: 10px;

                
            }
        }
    }

    .Enlaces {
        display: flex;
        // align-items: center;
        justify-content: space-around;
    }

    .FooterImage {
        width: 100%;
        position: absolute;
        bottom: 99.9%;
        left: 0px;
        object-fit: cover;
        object-position: center center;
        border: none;
    }

    .Copyright {
        position: absolute;
        bottom: 5px;
        left: 0;
        right: 0;
    }
}


@media(min-width:576px) {
    .Footer {
        padding-top: 30px;


        .FooterContent {



            .Enlaces {

                padding: 30px;

            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
        .Footer {
        padding-top: 30px;


        .FooterContent {
            flex-direction: row;

            .Enlaces {

                padding: 30px;

                .Ministerios {
                    margin: 0px 15px;
                }

                .RedesSociales {
                    display: none;
                }
            }
        }


        .FooterImage {
            bottom: 99%;
        }
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .Footer {


        .FooterContent {
            .Enlaces {
                .FooterLink {
                    flex-direction: row-reverse;
                    align-items: flex-start;
                    text-align: left;
                }

                .ContactoTelefonico {
                    P {
                        text-align: left;
                    }

                    .Contacto {

                        .Title {
                            text-align: left;
                        }

                        .Value {
                            text-align: left;

                        }
                    }
                }

                .Ministerios {
                    text-align: left;
                }

            }
        }

    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {
    .Footer {

    .FooterContent {

        .ContainerLogo {
            flex-direction: row;
            margin-right: 80px;
    



            .ImageContainer{
                padding: 10px;
            }
            
            .DireccionContainer{
                text-align: left;
                align-self: flex-start;
                width: 40%;
            }


        }

        .Enlaces {
            align-items: start;
            .Ministerios {text-align: left;}

            .FooterLink{
                .ContactoTelefonico{
                    margin-left:80px;
                }
                .DireccionContainer{
                    margin: 0px 50px;
                }
            }

        }
    }

   
}

}