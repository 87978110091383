.Container {
    padding: 10px 10px;
    align-items: stretch;
    
}


.LinkDeInteres {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    padding-top: 15px;
    box-shadow: 0 3px 6px #00000080;
    border-radius: 1em;
    overflow: hidden;
    color: #000000;
    cursor: pointer;
    background-color: white;

    text-decoration: none;

    position: relative;
    min-height: 65px;


    overflow: hidden;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        background-color: #6F6F6F;
        height: 10px;
        width: 80%;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        background-color: #98BB41;
        height: 10px;
        width: 45%;
    }


    .Icon {
        font-size: 30px;
        margin: 0px 15px 0px 10px;
    }



    .Label {
        flex-grow: 2;
        text-align: left;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        p {
            margin: 0px 5px;
        }
    }
}

@media(min-width:576px) {
    .Container {
        display: flex;
    }
    .LinkDeInteres {
        flex-direction: column;
        width: 150px;

        .Label {
            text-align: center;
            flex-direction: column;
        }

    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
    .LinkDeInteres {
        flex-direction: row;
        width: 250px;

        .Label {
            text-align: left;
            align-items: flex-start;
        }
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {}