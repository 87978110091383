@font-face{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 200;
    src: url('./../../fonts/Source_Sans_Pro/SourceSansPro-ExtraLight.ttf'); 
}
@font-face{
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 200;
    src: url('./../../fonts/Source_Sans_Pro/SourceSansPro-ExtraLightItalic.ttf'); 
}
@font-face{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: url('./../../fonts/Source_Sans_Pro/SourceSansPro-Light.ttf'); 
}
@font-face{
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 300;
    src: url('./../../fonts/Source_Sans_Pro/SourceSansPro-LightItalic.ttf'); 
}
@font-face{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('./../../fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf'); 
}
@font-face{
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    src: url('./../../fonts/Source_Sans_Pro/SourceSansPro-Italic.ttf'); 
}
@font-face{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url('./../../fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf'); 
}
@font-face{
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 600;
    src: url('./../../fonts/Source_Sans_Pro/SourceSansPro-SemiBoldItalic.ttf'); 
}
@font-face{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    src: url('./../../fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf'); 
}
@font-face{
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 700;
    src: url('./../../fonts/Source_Sans_Pro/SourceSansPro-BoldItalic.ttf'); 
}
@font-face{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 900;
    src: url('./../../fonts/Source_Sans_Pro/SourceSansPro-Black.ttf'); 
}
@font-face{
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 900;
    src: url('./../../fonts/Source_Sans_Pro/SourceSansPro-BlackItalic.ttf'); 
}
@font-face{
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 200;
    src: url('./../../fonts/Source_Serif_Pro/SourceSerifPro-ExtraLight.ttf'); 
}
@font-face{
    font-family: 'Source Serif Pro';
    font-style: italic;
    font-weight: 200;
    src: url('./../../fonts/Source_Serif_Pro/SourceSerifPro-ExtraLightItalic.ttf'); 
}
@font-face{
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 300;
    src: url('./../../fonts/Source_Serif_Pro/SourceSerifPro-Light.ttf'); 
}
@font-face{
    font-family: 'Source Serif Pro';
    font-style: italic;
    font-weight: 300;
    src: url('./../../fonts/Source_Serif_Pro/SourceSerifPro-LightItalic.ttf'); 
}
@font-face{
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 400;
    src: url('./../../fonts/Source_Serif_Pro/SourceSerifPro-Regular.ttf'); 
}
@font-face{
    font-family: 'Source Serif Pro';
    font-style: italic;
    font-weight: 400;
    src: url('./../../fonts/Source_Serif_Pro/SourceSerifPro-Italic.ttf'); 
}
@font-face{
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 600;
    src: url('./../../fonts/Source_Serif_Pro/SourceSerifPro-SemiBold.ttf'); 
}
@font-face{
    font-family: 'Source Serif Pro';
    font-style: italic;
    font-weight: 600;
    src: url('./../../fonts/Source_Serif_Pro/SourceSerifPro-SemiBoldItalic.ttf'); 
}
@font-face{
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 700;
    src: url('./../../fonts/Source_Serif_Pro/SourceSerifPro-Bold.ttf'); 
}
@font-face{
    font-family: 'Source Serif Pro';
    font-style: italic;
    font-weight: 700;
    src: url('./../../fonts/Source_Serif_Pro/SourceSerifPro-BoldItalic.ttf'); 
}
@font-face{
    font-family: 'Source Serif Pro';
    font-style: normal;
    font-weight: 900;
    src: url('./../../fonts/Source_Serif_Pro/SourceSerifPro-Black.ttf'); 
}
@font-face{
    font-family: 'Source Serif Pro';
    font-style: italic;
    font-weight: 900;
    src: url('./../../fonts/Source_Serif_Pro/SourceSerifPro-BlackItalic.ttf'); 
}
