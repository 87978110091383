.RedesSociales {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;

    display: none;
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
    .RedesSociales {
        margin-left: 10px;
        display: flex;
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .RedesSociales {
        margin-left: 30px;
        display: flex;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {}