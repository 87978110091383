.NewsList {

    display: flex;
    flex-direction: column;

    .MainNews {
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
    }

    >div {
        margin: 10px;
    }

    .Noticias {
        display: flex;
        flex-direction: column;

        .MainNewsPrincipal {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: stretch;
            flex-grow: 2;
        }
        order: 2
    }

    .Links {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        order: 1
    }
    .MinorNews{
        flex-direction: column;
    }
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    .NewsList {
        .Links {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
        .MinorNews{
            flex-direction: column;
        }
    }

}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
    .NewsList {
        .MainNews {
            flex-direction: row;

            .Noticias {
                width: 70%;

                .MainNewsPrincipal {
                    width: 100%;
                }
                order:1
            }

            .Links {
                margin-top: 75px;
                width: 30%;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                order: 2
            }
        }

        .OtrasNoticias {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .NoticiasMasVistas {
            .ListaNoticias {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;

            }
        }
        .MinorNews{
            flex-direction: row;
        }
    }
}


// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .NewsList {
        .MainNews {
            .Noticias {

                // width: 70%;
                .MainNewsPrincipal {
                    flex-direction: row;
                    justify-content: space-between;

                }
            }

            .Links {
                width: 30%;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
            }
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {}