.Mirador {
    width: 100%;
    height: 60vh;
    position: relative;


    .Image {
        height: 100%;
        width: 100%;
    }

    .Title {
        position: absolute;
        color: white;
        text-transform: uppercase;
        bottom: 0;
        padding: 5px 10px;
        border-radius: 2px;
    }
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    .Mirador {

        .Title {

            bottom: 30px;

        }
    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {}