.AlignText{
    text-align: left;
}
.NoticiaSmall{
    padding: 15px;
    width: 100%;
    height: 210px;

    .NoticiaSmallCard{
        position: relative;
        box-shadow: 0px 3px 6px #00000080;
        display: flex;
        border-radius: 20px;
        flex-direction: column;
        // padding: 20px;
        align-items: stretch;
        background-color: white;
        
        overflow: hidden;
        height: 100%;
    
        .ShareButtonC{
            
            div:first-child{
                right: 0;
                top: 0;
    
            }
            div:last-child{
               
                top: 0;
    
            }
        }
    
        .Actions {
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 0;
            left: 20px;
    
            small {
                margin: 5px 5px 5px 10px;
            }
    
            button {
                border: none;
                border-radius: 100px;
                background-color: #89BD31;
                color: white;
            }
    
            div {
                border: none;
                border-radius: 100px;
                background-color: #AFAFAF;
                color: white;
                width: 60px;
            }
        }
    
        .Titular {
            @extend .AlignText;
            margin: 0;
            padding: 0;
            margin-bottom: 20px;
            position: absolute;
            top: 0px;
            padding: 20px 20px 0px 20px;
            font-family: myriad-pro;
            font-size: 20px;
            font-weight: bold;
        
        }
        
        .Subtitulo{
            @extend .AlignText;
            // margin-bottom: 20px;
            margin: 0;
            padding: 0;
            height: 100px;
            position: absolute;
            bottom: 0px;
            padding: 20px;
            text-align: justify;
            font-family: Myriad-pro;
            font-size: 16px;
            font-weight: normal;
      
 
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
    .NoticiaSmall{
        width: 50%;
        margin: 0;
        padding: 15px;
        .NoticiaSmallCard{
            height: 100%;
        }
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .NoticiaSmall{
        width: 33%;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {
    .NoticiaSmall{
        width: 25%;
        margin: 0;
        padding: 15px;
        .NoticiaSmallCard{
            height: 100%;
            width: 100%;
        }
    }
}

