.LinkMenuGroup {

    .Title {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }

    .ListMenu {

        // height: 0px;
        opacity: 0;
        min-height: 0px;
        overflow: hidden;
        margin: 0px !important;
        transition: .3s all;

        &.Visible {
            opacity: 1;
        }

        &.Hide {
            opacity: 0;
        }

        li {
            margin: 0;
            padding: 0;
        }

        // background-color: green;
        a {
            text-decoration: none;
            color: black;

        }


    }
}