.PlaceCard {

    width: 100%;
    height: 300px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0px 3px 6px #00000080;
    margin: 15px 0px;
    // cursor: pointer;
    text-decoration: none;
    display: inline-block;
    background-color: white;

    .Image {
        width: 100%;
        height: 70%;
        position: relative;

        div {
            width: 100%;
            height: 100%;
            transition: all 1s;
        }
    }

    .Title {
        height: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 2s;

    }

    &:hover {

        .Image {
            div {
                transform: scale(1.1);
            }

        }

        .Title {
            transform: scale(1.3);
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {

    .Container {
        padding: 0px 10px;
        width: 100%;
    }

    .PlaceCard {}
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
    .Container {
        padding: 0px 10px;
        width: 50%;
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {}