li {
    margin: 0;
    padding: 0;
}

// background-color: green;
a {
    text-decoration: none;
    color: black;

}