@import './../../../../assets/styles/utils/color.scss';

.Container {
    width: 100%;
    overflow: hidden;
    border-radius: 2rem;
    margin-bottom: 50px;

    .ContainerView {
        display: flex;
        flex-direction: row;
        height: 100%;

        width: 200%;
    }
}

.Viewer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .Arrow {
        font-size: 30px;
        color: $color-clear-contrast;
        background-color: $color-dark-contrast;
        border-radius: 50%;
        cursor: pointer;
        margin: 0px 10px;

        &.Disabled{
            display: none;
        }
    }
}