.Container {
    margin: 10px;
    margin-bottom: 150px;

    .LinkList {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: start;
    }
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    .Container {
        margin-top: 0px;

        .LinkList {
            flex-wrap: nowrap;
            justify-content: flex-end;
        }

        .Lugares {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            margin: 0px 50px;
        }
    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
    .Container {

        .LinkList {
            flex-wrap: nowrap;
            justify-content: flex-end;
        }

        .Lugares {

            margin: 0px 20px;
        }
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .Container {

        .Lugares {

            margin: 0px 150px;
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {
    .Container {

        .Lugares {

            margin: 0px 200px;
        }
    }
}