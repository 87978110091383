.VideoModal{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8);
    top: 0;
    z-index: 1000000;

    display: flex;
    align-items: center;
    justify-content: center;

    .CloseIcon{
        color: white;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;


    }

    .Video{
        width: 100vw;
        
    }
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px){
    .VideoModal{
        .Video{
            width: 90vw;  
        }
    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px){
    .VideoModal{
        .Video{
            width: 80vw;
            
        }
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px){
    .VideoModal{
        .Video{
            width: 70vw;
            
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px){
    .VideoModal{
        .Video{
            width: 50vw;
        }
    }
}