.Loader{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9999999;
    height: 20px !important;
    width: 100px !important;
    

    .ball{
        width:20px !important;
        height: 20px !important;;
        background-color: #89BD31;
        display: inline-block;
        margin: 5px;
        border-radius: 50%;
    }

    .First{
        @extend .ball;
        animation: animate 3s infinite ;
    }

    .Second{
        @extend .ball;
        animation: animate 3s 1s infinite ;
    }

    .Third{
        @extend .ball;
        animation: animate 3s 2s infinite ;
    }
}

@keyframes animate{
    0%{
        transform: translateY(0px);
    }
    15%{
        transform: translateY(-15px);
    }
    
    28%{
        transform: translateY(3px);
        
    }
    30%{
        transform: translateY(2px);
        
    }
    33%{
        transform: translateY(0px);
        
    }
    100%{
        transform: translateY(0px);

    }
}