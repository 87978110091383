 @import './../../../assets/styles/utils/fonts.scss';
@import './../../../assets/styles/utils/color.scss';



 
 .Menu {
    z-index: 100000001;
    max-height: 100vh;
    height: 100vh;
    background-color: $color-clear-contrast;
    color: $color-dark-contrast;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    transform: translateY(-120%);
    opacity: 1;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s;
    box-shadow: 0px 3px 6px #00000036;


    &.Desplegado {
        transform: translateY(0%);
        opacity: 1;
    }


    .MenuContainer {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        padding: 25px 0px;
        height: 100%;
        overflow: hidden;


        .SideBar {
            background-color: $color-primary;
            background: linear-gradient(180deg, $color-primary, #4F7410);
            height: 100vh;
            width: 60px;
        }

        .MenuOptions {
            flex-grow: 3;
            display: flex;
            flex-direction: column;
            // margin-bottom: 25px;

            .MenuHeader{

                display: flex;
                justify-content: space-between;
                flex-direction: row;
                padding: 0px 20px;
                padding-bottom: 20px;


                .Cartagena{

                    align-items: center;
                    display: flex;

                    img{
                        &:first-child {
                            width: 44px;
                            height: 65px;
                        }

                        &:last-child {
                            width: 242px;
                            height: 42px;
                        }
                    }
                }

                .Cerrar{
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    
                    font-weight: bold;

                    .CloseButton {
                        font-size: 36px;
                    }

                    .CerrarLabel{
                        font-size: 30px;
                        display: none;
                    }
                }


            }

            

            .MenuItems{
                display: flex;
                flex-direction: column;
                overflow-y: auto;
            }
            

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                margin-top: 30px;

                li {
                    text-transform: uppercase;
                    padding: 5px 20px;
                    font-size: 18px;

                }
            }


        }

    }

    h1 {
        text-align: left;
        font-size: 16px;
        text-transform: uppercase;
        width: 50%;
        font-family: Source Sans Pro;
    }

    .Opciones {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;

        ul {
            list-style: none;
            margin: 0;
            padding: 10px;
        }
    }
}

@media(min-width:576px) {
    .Menu {
        .MenuContainer {
            .MenuOptions {
                .MenuHeader{
                    .Cerrar{
                        .CerrarLabel{
                            display: block;
                        }
                    }
                }
            }
        }
    }

}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
    .Menu {
    .MenuContainer {
        .MenuOptions {
            .MenuItems{
                flex-direction: row;
            }
        }
    }
}
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .Menu {
        height: 80vh;
        
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {}