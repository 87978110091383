.noticia{
    display: flex;
    flex-direction: column;
    margin-bottom: 90px;
    min-height: 50vh;
    transition: height .5s;
}
.btnVolver{
    position: absolute;
    top: 120px;
    left: 0px;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    font-size: 22px;
    font-weight: bold;
    padding: 5px;
    padding-left: 10px;
    cursor: pointer;

    -moz-user-select: none; 
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}
.titular{
    font-weight: bold;
    position: absolute;
    bottom: 0;
    text-align: center;
    background : linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5));
    width:100%;
}
.titular *{
    color: white;
    font-size: 2.8em;
    transform: translate(50%);
    right: 15%;
    width: 65%;
    position: relative;
}
.subtitulo{
    // font-size: 3em;
    text-align: justify;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 2%;
    margin-bottom: 1%;
}
.cuerpo{
    margin-left: 10%;
    margin-right: 10%;
}
.cuerpo p{
    // font-size: 1.54em;
    text-align: justify;
    width: 100%;

    * img {
        width: 100%;
    }
}
.cuerpo *{
    // font-size: 1em;
    text-align: justify;
}

.extraInfo{
    display: flex;
    align-items: center;
    margin-left: 10%;
    margin-right: 10%;

    .categoria{
        border: none;
        border-radius: 100px;
        background-color: #AFAFAF;
        color: white;
        width:60px;
        margin-left: 10px;
    }
}

.HeaderImage{
    height: 400px,
}


// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    .HeaderImage{
        height: 90vh,
    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
 .titular *{
  
    font-size: 3.2em;
    
}
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .titular *{
  
    font-size: 4.2em;
    
}
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {

}