.nonSelectable {
    -moz-user-select: none; 
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; 
 }

.Revista{
    margin: 50px;
    margin-top: 150px;
    min-height: 100vh;
    .Articulos{
        margin: 20px 0px;
        a{
            margin-top: 50px;
            display: block;
        }
    }
    margin-bottom: 50px;
}
.preview{
    height: 80%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
}
.preview canvas{
    width: 100% !important;
    height: 25% !important;
}

.previewDocument {
    position: absolute;
    top: 0;
    width: 90%;
}
.tituloDoc{
    height: 60px;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    color: white;
    font-size: 1.8em;
    width: 90%;
    font-weight: bold;
    text-transform: uppercase;
    padding-top: 5px;
    bottom: 0%;
}
.previewDocument div{
}
// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    .Revista{
        .Articulos{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            & > div {
                width: 50%;
            }
            a{
                width:25%;
            }
        }
    }
}


// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
    .Revista{
        .Articulos{
            & > div {
                width: 33%;
            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .Revista{
        .Articulos{
            & > div {
                width: 25%;
            }
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {}