.PlaceItem{
    // min-width: 100%;
    height: 80%;
    background-color: #ccc;
    position: relative;
    overflow: hidden;
    text-decoration: none;
    transition: all .2s;

    display: flex;
    align-items: flex-end;
    justify-content: center;

    img{
        position: absolute;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        
    }

    &:hover{
        height: 95%;
        border-radius: 20px;
        box-shadow: 0px 3px 6px #00000075;
    }
    

    .Image{

        background-position: center;
        background-size: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        
        transition: all .3s;
        // &:hover{
        //     width: 120%;
        //     height: 120%;
        // }
        
    }

    .PlaceTitle{
        position: relative;
        // height: 50%;
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background-color: #308CCB;
        color: white;
        min-height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px){
    .PlaceItem{
        // min-width: 50%;
        
        img{
            
        }
    
        .PlaceTitle{
            
        }
    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px){
    .PlaceItem{

        // min-width: 33.33333%;
        
        img{
            
        }
    
        .PlaceTitle{
            
        }
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px){
    .PlaceItem{

        // min-width: 25%;
        
       
    
        .PlaceTitle{
            
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px){
    .PlaceItem{

        // min-width: 20%;
        
        img{
            
        }
    
        .PlaceTitle{
            
        }
    }
}