.MainContainer {
    margin-top: -20px;
    padding: 0px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
    .MainContainer {
        padding: 0px 60px;
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .MainContainer {
        padding: 0px 100px;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {}