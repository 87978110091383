.InformationBanner{
    background-color: #C8102E;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // margin-bottom: 80px;
    padding: 10px 0px;

    .Icon{
        font-size: 24px;
    }

    .InformationTitle{
        display: flex;
        align-items: center;
        justify-content: center;
        .Icon{
            color: #FDD80D;
            margin-right: 10px;
        }
    }
    
    .InformationText{
        display: flex;
        align-items: center;
        justify-content: center;
        .Icon{
            margin-left: 20px;
        }
    }

}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
    .InformationBanner{
        flex-direction: row;

        .InformationTitle{
            margin-right: 5px;
            
        }
        
        .InformationText{
            margin-left: 5px;
        }
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .Alcalde{
        margin: 60px 100px 200px 100px;
        .DatosAlcalde{
            margin-left: 250px;
            margin-right: 250px;
        }
    }

}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {

}