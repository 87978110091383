
.DireccionTempalte{
    margin-bottom: 120px;
    margin-top: 55px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    .Title {
        // position: absolute;
        color: white;
        text-transform: uppercase;
        bottom: 0;
        padding: 5px 10px;
        border-radius: 2px;
        display: inline-block;
        text-align: left;
        margin-bottom: 10px;

    }

    .DireccionContent{
        // display: flex;
        margin: 30px;
    }

    .ImagenPrincipal{
        width: 100%;
    }
}


.Loader {
    height: 50vh;
    position: relative;
}

.TextContainer{
    // margin: 30px;
}

.Rich{
    text-align: left;
}

.Descargas{
    text-align: left;
    
    a{
        padding: 5px 0px;
        font-size: 1em;
        display: flex;
        svg {
            margin-right: 10px;
            font-size: 1.3em;
        }
    }
}


// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {

    .DireccionTempalte{
        .DireccionContent{
            margin: 30px 50px ;
            margin-bottom: 70px;
        }

         .ImagenPrincipal{
            float: right;
            width: 30%;
            margin: 20px 35px;
            margin-top: 0px;
        }
    }
    .Loader {
        height: 50vh;
        position: relative;
    }

    .TextContainer{
        // margin: 30px 50px ;
        // margin-bottom: 70px;
    }


}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {

    .DireccionTempalte{
        .DireccionContent{
            margin: 30px 70px ;
        margin-bottom: 70px;
        }
    }
    .Loader {
    height: 50vh;
    position: relative;
    }

    .TextContainer{
        // margin: 30px 70px ;
        // margin-bottom: 70px;
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {

    .DireccionTempalte{
        .DireccionContent{
            margin: 30px 0px ;
            margin-bottom: 70px;
        }
    }
    
    .Loader {
        height: 50vh;
        position: relative;
    }

    .TextContainer{
        margin: 20px ;
        // width: 50%;
        // margin-bottom: 70px;
    }


}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {}