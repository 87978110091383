.PaginadorContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
    background-color: white;
    text-align: center;

    .Paginador{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    .PaginatorCountItem{
        margin: 5px;
        display: inline-block;
        color: black;
        
        border-radius: 50px;
        width: 24px;
        height: 24px;

        font-size: 20px;
        vertical-align: middle;
        line-height: 24px;

        &.Selected {
            background-color: #89BD31;
            color: white;
        }
    }

    .Arrow{
        color: black
    }

    span{
        cursor: pointer;
    }
}


}