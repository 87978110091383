.Modal{
    width: 100%;
    // height: 100%;
    border-radius: 20px;
    background-color: #FFFFFF;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position:relative;

    .ButtonContainer{
        display: flex;
        justify-content: space-around;
            div{
                min-width: 100px;
            }
        
    }

    .Close {
        position:absolute;
        right: 10px;
        top: 10px;
        font-size: 42px;
        cursor: pointer;
    }
}

.ImageConainer{
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;

    .ImagenInformativa{
        height: 100%;
        position: absolute;
        top:0;
        bottom: 0;
        /* width: 100%; */
        object-fit: cover;
        object-position: center center;
    }

}

.Left{
    text-align: left;
    margin-bottom: 20px; 
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    .Modal{
        padding: 20px;

        .Close {
            // display: none;
        }
    }
}