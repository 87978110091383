.UserPresentation {
    width: 250px;
    overflow: hidden;
    text-align: center;

    .CircleImage{
        img{
            border-radius: 50%;
        }
    }

    .Nombre{
        margin-top: 20px;

    }

    .Cargo{
        margin-top: 15px;
        color: #909090;
        font-weight: 600;
    }
}