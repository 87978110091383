.Accessibility {
    position: fixed;
    z-index: 10000;
    background-color: #000000ad;
    width: 100%;
    padding: 10px;
    top: 280px;
    right: 0;

    display: flex;
    flex-direction: column;

    width: 6rem;
    border-radius: 20px 0px 0px 20px;

    .Link {
        color: white;
        cursor: pointer;
        padding: .8rem;
        font-size: 2rem;
        width: 4rem;
        height: 4rem;
        margin: .2rem;
        border-radius: .2rem;

        &.Selected {
            background-color: #8e8e8e80;
        }
    }

}

.Card {
    background-color: #000000ad;
    position: absolute;
    right: 80px;
    z-index: 10000;
    // width: 150px;
    // height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 2rem;
    padding: 10px 20px;

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        // margin: 10px 20px;
        color: white;

        span {
            font-size: 1.3rem;
            margin: 3px;
        }
    }
}

.Text {
    p {
        text-decoration: underline;
    }

}

.Button {
    cursor: pointer;
    padding: 15px;
    border-radius: 1rem;
    min-width: 120px;

    &:hover {
        background-color: #8e8e8e80;
    }
}