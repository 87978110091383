.ResultList{
    position: absolute;
    background-color: #0000009A;
    padding: 20px;
    text-align: left;
    width: 100%;
    overflow-y: auto;
    height: 100%;
    top: 0;
    padding-top: 100px;
    .Header{
        display: flex;
        justify-content: space-between;
        .ResultadoText{
            color: white;
            text-align: left;
            margin: 0;
            margin-bottom: 10px;
            font-size: 14px;
            padding: 0;
        }
    
        .CloseButton{
        
            font-size: 24px;
            cursor: pointer;
        }
    }

    .SinCoincidencias{
        // width: 100%;
        background-color: white;
        color: black;
        border-radius: 20px;
        position: absolute;
            top: 130px;
        bottom: 20px;
        left: 20px;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        text-transform: uppercase;
        color: gray;
    }
}


@media(min-width:992px) {
    .ResultList{
        position: relative;
        padding-top: 20px;
        border-radius: 20px;

            .SinCoincidencias{
        // width: 100%;
        background-color: white;
        color: black;
        border-radius: 20px;
        position: absolute;

        top: 50px;
        bottom: 10px;
        left:  10px;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        text-transform: uppercase;
        color: gray;
    }
    }
}
