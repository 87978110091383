.ShareButton{

    .Share {
        width: 45px;
        height: 45px;
        position: absolute;
        z-index: 1;
        background-color: white;
        padding: 5px;
        border-radius: 100px;
        color: #C8102E;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;


        
            right: 10px;
            top: 10px;
            z-index: 2;

            .ShareIcon {
                font-size: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

        

        

    }





    .ShareOptions {
            background-color: white;
            font-size: 20px;
            display: flex;
            height: 40px;
            padding: 2px;
            border-radius: 50px 0px 0px 50px;
            position: absolute;
            top: 12.5px;
            opacity: 0;
            padding-right: 19px;
            right: 39px;
            transform: translateX(200px);
            transition: .4s all;
            z-index: 1;

            align-items: center;
            justify-content: space-between;

            &.Open {
                opacity: 1;
                transform: translateX(0px);

            }


            .ShareItemOption {
                margin: 5px;
                display: flex;
                align-items: center;
            }
        }
}


