@import './../../assets/styles/utils/color.scss';

.no-seleccionable {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}

.CardLink{
    width: 100%;
    box-sizing: border-box;
    padding: 10px 0px;
    // height: 180px;
    overflow: hidden;
    
    .Container{
        
        background-color: white;
        box-shadow: 0px 3px 6px #00000029;
        width: 100%;
        box-sizing: border-box;
        // height: 180px;
        border-radius: 20px;
        
        
    
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        .RoundImage{
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: unset !important;
            img{
                margin: 10px;
                width: 80px;
                height: 80px;
                border-radius: 100%;
                background-color: #f7f7f7;
                
            }
        }

        .CardTitle{
            position: absolute;
            top: 10px;
            font-size: 14px;
            font-weight: bold;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
        }

        .Body{
            font-size: 12px;
        }
        
        .Info{
            flex-grow: 3;
            height: 120px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            position: relative;
            width: 150%;
            padding-right: 10px;



            p{
                text-align: left;
                margin: 0;
                padding: 0;
            }

            .Button{
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                border-radius: 20px 0px 0px 0px;
                background-color: $color-primary;
                color: $color-clear-contrast;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                cursor: pointer;
                @extend .no-seleccionable;
            }
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px){
    .CardLink{
        width: 50%;
        &:nth-child(odd){

            padding-right: 10px;
        }
        &:nth-child(even){

            padding-left: 10px;
        }

    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px){
    .CardLink{
        width: 100%;
        padding: 10px;

        .Container{
            // padding-bottom: 40px;
            width: 100%;
            flex-direction: column;



            .Info{
                width: 100%;
                padding: 0px 10px;
                .CardTitle{
                    left: 0;
                    right: 0;
                    margin: auto;
                }
                p{
                    text-align: center;
                }
                .Button{
       
                    border-radius: 0px;
                    
                }
            }

        }
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px){
    .CardLink{
        // width: 100%;

        .Container{
            padding-bottom: 0px;
            width: 100%;
            flex-direction: row;

            .Info{
                width: 150%;
                padding: 0;
                padding-right: 10px ;
                p{
                    text-align: left;
                }

                .Button {
                    border-radius: 20px 0px 0px 0px;
                }
            }
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px){

}