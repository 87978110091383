.Clock {
    color: white;
    background-color: #000000ad;
    padding: 15px;
    // font-size: 3em;
    border-radius: 20px 0px 0px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    p {
        font-size: 16px;
        padding: 0;
        margin: 0;
        // margin-right: 10px;
        margin: 0px 5px;
    }

    time {
        font-size: 1.5rem;
        font-weight: bold;
    }
    display: none;
}

@media(min-width:576px) {
    .Clock {
        display: flex;
    }
}