.Cartagena {
    .Entrada {}
}


// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    .Cartagena {
        .Entrada {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            margin: 15px 0px;

            div {
                width: 100%;
            }

            .Parrafo {
                width: 150%;
                margin-right: 20px;
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .Cartagena {

        .Contenido {
            margin: 0 200px;

        }

        .Entrada {
            // align-items: stretch;
        }

        .Parrafo {
            // flex-grow: 3;
            width: 100%;
            margin-right: 20px;
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {

    .Cartagena {

        .Contenido {
            margin: 0 100px;

        }

        .Entrada {
            // align-items: stretch;
        }

        .Parrafo {
            // flex-grow: 3;
            width: 100%;
            margin-right: 20px;
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1440px) {

    .Cartagena {

        .Contenido {
            margin: 0 300px;

        }
    }
}