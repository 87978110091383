@import './../../assets/styles/utils/color.scss';

.Title100 {
    font-family: Myriad-pro;
    font-size: 4.6em;
    font-weight: bold;
    padding: 0px;
    margin: 0;
}

.Title200 {
    font-family: Myriad-pro;
    font-size: 3.6em;
    font-weight: bold;
    padding: 0px;
    margin: 0;
}

.Title300 {
    font-family: Myriad-pro;
    font-size: 3.2em;
    font-weight: bold;
    padding: 0px;
    margin: 0;
}

.Title400 {
    font-family: Myriad-pro;
    font-size: 2.4em;
    font-weight: bold;
    padding: 0px;
    margin: 0;
}

.TitlePrincipal {
    text-align: left;
    position: relative;
    margin: 20px 0px;

    &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        width: 100px;
        height: 3px;
        background-color: $color-primary;
    }
}

.T20 {
    font-family: Myriad-pro;
    font-size: 3.3em;
    font-weight: bold;
    padding: 0px;
    margin: 0;
}
.T16 {
    font-family: Myriad-pro;
    font-size: 2.666em;
    font-weight: bold;
    padding: 0px;
    margin: 0;
}
.T24 {
    font-family: Myriad-pro;
    font-size: 4em;
    font-weight: bold;
    padding: 0px;
    margin: 0;
}

.T36 {
    font-family: Myriad-pro;
    font-size: 6em;
    font-weight: bold;
    padding: 0px;
    margin: 0;
}
.T30 {
    font-family: Myriad-pro;
    font-size: 5em;
    font-weight: bold;
    padding: 0px;
    margin: 0;
}

.Left{
    text-align: left;
}