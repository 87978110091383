@import './../../assets/styles/utils/color.scss';

// Extra Small devices (old phones, 575px and down)

.Container {
    box-sizing: border-box;
    // padding: 20%;

    // position: relative;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 20px 0px;
    overflow: hidden;
    // position: absolute;
    // top: 0;
}

.Img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
}

.Articulo {
    // position: absolute;
    top: 0;
    background: $color-dark-contrast;
    // border-radius: 2rem;
    position: relative;
    overflow: hidden;
    width: 100%;
    border-radius: 1em;
    // height: 100%;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    display: block;
    cursor: pointer;

    padding-top: 100%;
    position: relative;

    img {
        width: 100%;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
    }




    .Label {
        
            position: absolute;
            color: $color-clear-contrast;
            background: $color-dark-contrast;
            bottom: 0;
            width: 100%;
            height: 60px;
            padding: 0px;
            margin: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: bold;
        
    }

}

.Video {

    &.Show {
        display: block;
    }
}


// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    .Container {
        padding: 10px;
    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
    .Container {}
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .Container {}
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {
    .Container {}
}


// Extra Small devices (old phones, 575px and down)
// Small devices (landscape phones, 576px and up)
// Medium devices (tablets, 768px and up)
// Large devices (desktops, 992px and up)
// Extra large devices (large desktops, 1200px and up)