.ModalViewer{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100vh;
        z-index: 9999999998;
        display: flex;
        align-items: center;

        .ModalContainer{
            width: 100%;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            padding: 50px;
            margin: auto;


            // position: absolute;

            z-index: 9999999999;
            .Close {
                position: absolute;
                top: 10px;
                right: 10px;
                font-size: 32px;
                color: white;
                cursor: pointer;
                display: none;
            }

            .ViewerContainer{
                // width: 100%;
                // height: 100%;
                overflow: hidden;
                z-index: 1;
                .Viewer{
                    // height: 100%;
                    height: 60vh;
                    display: flex;
                    flex-direction: row;
                    overflow: hidden;
                    transition: .5s all;
                    align-items: stretch;
                }
            }

            .IndicatorContainer{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 0;
                padding: 10px;
                .Indicator{
                    display: block;
                    width: 20px;
                    height: 20px;
                    background-color: white;
                    margin: 5px;
                    border-radius: 50%;
                    transition: background-color .5s;

                    &.Selected{
                        background-color: #89BD31;
                    }
                }
            }
        }

    .Overlay{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #0000009A;
    }
}



// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    .ModalViewer{
        .ModalContainer{
            // padding: 30px 5%;
            // margin: 40px auto;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .Close {
                display: block;
            }
        
            .ViewerContainer{
                width:506px;
                height: 672px;
                .Viewer{
                    height: 100%;
                }

            }
            
                    .IndicatorContainer{
                        position: relative;
                    }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
.ModalViewer{
        .ModalContainer{
            // padding: 80px 10%;
            // margin: 40px auto;
            .ViewerContainer{
                width:600px;
                height: 730px;

            }
        }
    }
}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .ModalViewer{
        .ModalContainer{
            // padding: 60px 25%;
        }
        .Overlay{
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #0000009A;
        }
    }

}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {
.ModalViewer{
        .ModalContainer{
            // padding: 80px 30%;
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1500px) {
    .ModalViewer{
        .ModalContainer{
            // padding: 150px 35%;
        }
    }

}
@media(min-width:1800px) {
    .ModalViewer{
        .ModalContainer{
            // padding: 100px 35%;
        }
    }

}
@media(min-width:2000px) {
    .ModalViewer{
        .ModalContainer{
            // padding: 80px 35%;
        }
    }

}
@media(min-width:2200px) {
    .ModalViewer{
        .ModalContainer{
            // padding: 120px 40%;
        }
    }

}