.sin-espacio{
   
        margin: 0;
        padding: 0;
    
}

.Paragraph100{
    font-family: myriad-pro;
    font-size: 2.4em;
    font-weight: normal;
    @extend .sin-espacio;
}
.Paragraph200{
    font-family: myriad-pro;
    font-size: 2.4em;
    font-weight: bold;
    @extend .sin-espacio;
}
.Paragraph350{
    font-family: myriad-pro;
    font-size: 1.6em;
    font-weight: normal;
    @extend .sin-espacio;
}
.Paragraph300{
    font-family: myriad-pro;
    font-size: 2em;
    font-weight: normal;
    @extend .sin-espacio;
}
.P16{
    font-family: myriad-pro;
    font-size: 2.66em;
    font-weight: normal;
    margin: 0;
        padding: 0;
    @extend .sin-espacio;

    & > *,>::before, >::after {
        font-size: .6em;
    }
}
.P14{
    font-family: myriad-pro;
    font-size: 2.33333em;
    font-weight: normal;
    margin: 0;
    padding: 0;
    @extend .sin-espacio;
    > * {
        font-size: 1.1428em;
        font-weight: normal;
    }
}

// .Wrapper{
    
//     *, ::after, ::before {
//         font-size: ; 
//     }
// }
