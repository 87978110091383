.ListaNoticias{
    margin: 20px;
    margin-top: 150px;

    & > section {
        margin: 20px 0px;
    }

    .NoticiasImportantes{
        display: flex;
        flex-wrap: wrap;

        .ContenedorNoticia{
            width: 100%;
            padding: 10px;
            
            .Noticia{
                width: 100%;
                height: 100%;
            }
        }

    }

    .OtrasNoticias{

        display: flex;
        flex-wrap: wrap;
        
        .ContenedorNoticia{
            width: 100%;
            padding: 10px;
            .Noticia{
                width: 100%;
                height: 100%;;
            }
        }
    }

    .Mas_vistas{
        display: flex;
        flex-wrap: wrap;
    }

    .NoticiasPaginadas{

        display: flex;
        flex-wrap: wrap;
        
        .ContenedorNoticia{
            width: 100%;
            padding: 10px;
            .Noticia{
                width: 100%;
                height: 100%;;
            }
        }
    }

    .Paginador{
        margin: 20px 0px;
        width: 100%;
    }
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    .ListaNoticias{
        margin: 50px;
    }

}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {
.ListaNoticias{
        margin: 70px;
    .NoticiasImportantes{
       
        .ContenedorNoticia{
            width: 50%;
        }

    }

    .OtrasNoticias{
        
        .ContenedorNoticia{
            width: 50%;
            
        }
    }

    .NoticiasPaginadas{


        
        .ContenedorNoticia{
            width: 50%;

        }
    }

    
}
}


// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .ListaNoticias{
        margin: 100px;
    .NoticiasImportantes{
       
        .ContenedorNoticia{
            width: 50%;
        }

    }

    .OtrasNoticias{
        
        .ContenedorNoticia{
            width: 25%;
            
        }
    }

    .NoticiasPaginadas{


        
        .ContenedorNoticia{
            width: 25%;

        }
    }

    
}
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {}