@import './../../../../assets/styles/utils/color.scss';

.Places{
    padding: 30px 0px;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .Arrow{
        font-size: 30px;
        color: $color-clear-contrast;
        background-color: $color-dark-contrast;
        border-radius: 50%;
        cursor: pointer;
        margin: 0px 10px;

        &.Disabled{
            display: none;
        }
    }

    .Container{
        height: 100%;
        width: 100%;
        border-radius: 2em;
        overflow: hidden;

        .PlacesContainer{
            height: 100%;
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
        }

        .Rail{
            height: 100%;
            width: 100%;
            
            display: flex;
        }
    }

}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px){
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px){
}

// Large devices (desktops, 992px and up)
@media(min-width:992px){
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px){
}