.LinksGob {

    padding: 20px;
    display: flex;
    flex-direction: row;
}

// Small devices (landscape phones, 576px and up)
@media(min-width:576px) {
    .LinksGob {
        flex-direction: row;
    }
}

// Medium devices (tablets, 768px and up)
@media(min-width:768px) {}

// Large devices (desktops, 992px and up)
@media(min-width:992px) {
    .LinksGob {
        // align-items: flex-end;   
        justify-content: flex-end;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media(min-width:1200px) {
    .Container {
        padding: 5%;
    }
}