.GaleriaCartagena {
    display: flex;
    align-items: stretch;

    .Image {
        padding: 5px;
        height: 200px;
        display: flex;

    }
}